import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 35 35"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9.115 30.626h5.833a2.925 2.925 0 0 1-2.917 2.916 2.925 2.925 0 0 1-2.916-2.916Zm-2.917-1.459h11.667v-2.916H6.198v2.916Zm16.77-13.854c0 5.571-3.878 8.546-5.497 9.48H6.59c-1.618-.934-5.497-3.909-5.497-9.48 0-6.037 4.9-10.937 10.937-10.937 6.038 0 10.938 4.9 10.938 10.937Zm-2.916 0c0-4.419-3.602-8.02-8.02-8.02-4.42 0-8.022 3.601-8.022 8.02 0 3.602 2.173 5.673 3.428 6.563h9.187c1.254-.89 3.427-2.96 3.427-6.563Zm10.019-3.106-1.998.919 1.998.918.919 1.998.918-1.998 1.998-.918-1.998-.92-.918-1.997-.92 1.998Zm-3.456-1.998 1.37-3.004 3.005-1.371-3.005-1.37-1.37-3.005-1.371 3.004-3.004 1.371 3.004 1.37 1.37 3.005Z"
    }, null, -1)
  ])))
}
export default { render: render }