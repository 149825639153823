import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.966 1.91A13.121 13.121 0 0 0 15 1.876V0a15 15 0 0 1 1.104.041l-.138 1.87Zm3.757.844a13.13 13.13 0 0 0-1.847-.56l.41-1.83c.719.161 1.426.375 2.112.641l-.675 1.75Zm2.569 1.332a13.144 13.144 0 0 0-.823-.507l.924-1.63a15.05 15.05 0 0 1 1.836 1.225l-1.153 1.48a13.12 13.12 0 0 0-.784-.566v-.002Zm3.439 3.356c-.371-.527-.78-1.026-1.225-1.493l1.358-1.293c.506.534.975 1.106 1.4 1.706l-1.533 1.08Zm1.395 2.535c-.123-.297-.257-.59-.402-.878l1.675-.843c.33.658.613 1.34.843 2.04l-1.78.587a13.18 13.18 0 0 0-.336-.906Zm.993 4.7a13.123 13.123 0 0 0-.187-1.921l1.847-.32c.125.725.198 1.46.217 2.195l-1.875.046h-.002Zm-.245 2.884c.062-.319.112-.635.152-.956l1.861.23c-.09.732-.234 1.456-.43 2.166l-1.808-.5c.086-.31.161-.623.225-.94Zm-1.785 4.46c.345-.543.649-1.113.911-1.702l1.714.76a15.12 15.12 0 0 1-1.04 1.946l-1.585-1.003Zm-1.808 2.26c.229-.229.448-.465.657-.708l1.42 1.224c-.24.279-.49.55-.751.81L24.28 24.28Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.006 1.875a13.125 13.125 0 1 0 9.281 22.406l1.326 1.326A15.002 15.002 0 1 1 15.006 0v1.875Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.688 6.25a.937.937 0 0 1 .937.938v9.768l6.09 3.48a.938.938 0 0 1-.93 1.628l-6.563-3.75a.936.936 0 0 1-.472-.814V7.187a.938.938 0 0 1 .938-.937Z"
    }, null, -1)
  ])))
}
export default { render: render }