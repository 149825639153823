import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/clients/members/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/clients/members/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/app/clients/members/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import firebase_client_zXNDa0wxFH from "/app/clients/members/plugins/firebase.client.ts";
import posthog_client_fUucxKWhZE from "/app/clients/members/plugins/posthog.client.ts";
import pwa_client_PLDhtd2phb from "/app/clients/members/plugins/pwa.client.ts";
import sentry_client_shVUlIjFLk from "/app/clients/members/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/clients/members/plugins/vue-query.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  firebase_client_zXNDa0wxFH,
  posthog_client_fUucxKWhZE,
  pwa_client_PLDhtd2phb,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]