import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M28.125 26.25S30 26.25 30 24.375s-1.875-7.5-9.375-7.5-9.375 5.625-9.375 7.5 1.875 1.875 1.875 1.875h15Zm-14.959-1.875a.48.48 0 0 1-.041-.008c.002-.495.313-1.93 1.425-3.225 1.035-1.213 2.854-2.392 6.075-2.392 3.22 0 5.038 1.181 6.075 2.392 1.112 1.294 1.421 2.732 1.425 3.226l-.015.003a.574.574 0 0 1-.026.004H13.166Zm7.459-11.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm5.625-3.75a5.625 5.625 0 1 1-11.25 0 5.625 5.625 0 0 1 11.25 0ZM13.005 17.4a11.024 11.024 0 0 0-2.306-.463c-.44-.042-.882-.063-1.324-.062-7.5 0-9.375 5.625-9.375 7.5 0 1.25.624 1.875 1.875 1.875H9.78a4.196 4.196 0 0 1-.405-1.875c0-1.894.707-3.829 2.044-5.445a9.917 9.917 0 0 1 1.586-1.53Zm-3.78 1.35A10.3 10.3 0 0 0 7.5 24.375H1.875c0-.488.308-1.931 1.425-3.233 1.022-1.192 2.798-2.354 5.925-2.39v-.002Zm-6.412-8.438a5.625 5.625 0 1 1 11.25 0 5.625 5.625 0 0 1-11.25 0Zm5.624-3.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
    }, null, -1)
  ])))
}
export default { render: render }