import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 11"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M13.489 1.25 7.993 6.747 2.496 1.25A1.412 1.412 0 1 0 .498 3.248L7.001 9.75a1.41 1.41 0 0 0 1.997 0l6.503-6.502a1.412 1.412 0 0 0 0-1.998c-.553-.538-1.459-.552-2.012 0Z",
      style: {"fill-rule":"nonzero"},
      transform: "rotate(180 8 5.5)"
    }, null, -1)
  ])))
}
export default { render: render }