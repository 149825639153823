import { type MarketId, defaultMarket } from '@ruokaboksi/api-client';
import type { CookieRef } from '#app';

/**
 * Composable for fetching cookie about the current market.
 * @returns The current market id.
 */
export default function useCurrentMarket() {
  const market: CookieRef<MarketId> = useCookie('market', {
    sameSite: 'strict',
    secure: true,
  });
  const { subscription } = useCustomerSubscriptions();
  const { getDeliveryAreaForSubscription } = useDeliveriesApi();
  const { data: deliveryArea } = getDeliveryAreaForSubscription(subscription);

  if (deliveryArea.value?.market && !market.value) {
    market.value = deliveryArea.value?.market;
  }

  if (
    deliveryArea.value?.market &&
    market.value !== deliveryArea.value?.market
  ) {
    market.value = deliveryArea.value?.market;
  }

  if (!market.value) {
    market.value = window.location.hostname.includes('yummybox.cz')
      ? 'CZE'
      : defaultMarket;
  }

  return {
    market,
  };
}
