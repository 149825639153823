import RuokaboksiBox from '@/assets/images/ruokaboksi-box.webp';
import RuokaboksiPlaceholderCard from '@/assets/images/ruokaboksi-placeholder-card.webp';
import RuokaboksiWithIngredients from '@/assets/images/ruokaboksi-with-ingredients.webp';
import YummyBoxWithIngredients from '@/assets/images/yummy-box-with-ingredients.webp';
import YummyBox from '@/assets/images/yummy-box.webp';
import YummyPlaceholderCard from '@/assets/images/yummy-placeholder-card.webp';
import type { i18n } from '@/config/i18n';

export const getBoxImage = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return YummyBox;
    default:
      return RuokaboksiBox;
  }
};

export const getBoxWithIngredientsImage = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return YummyBoxWithIngredients;
    default:
      return RuokaboksiWithIngredients;
  }
};

export const getPlaceholderCardImage = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return YummyPlaceholderCard;
    default:
      return RuokaboksiPlaceholderCard;
  }
};
