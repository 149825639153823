import type { CustomerFormData } from '@/models';
import type { Customer, CustomerUpdate } from '@ruokaboksi/api-client';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import type { Ref } from 'vue';

export const KEY_MY_CUSTOMER_INFO = 'myCustomerInfo';
export const KEY_MY_CUSTOMER_STATE = 'myCustomerState';
export const KEY_MY_CUSTOMER_LANGUAGE = 'myCustomerLanguage';
export const KEY_MY_ZENDESK_TOKEN = 'myZendeskToken';
export const KEY_MY_MAILING_LISTS = 'myMailingLists';
/**
 * Composable for fetching information from the Customers service.
 */
export default function useCustomerApi() {
  const { isLoggedIn } = useCurrentUser();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const { locale: localeCookie } = useLocale();

  const getMyCustomerInfo = () => {
    const queryFn = async () => {
      const response = await apiClient.customers.retrieve({
        customerId: '_me',
      });

      if (response.language !== localeCookie.value) {
        localeCookie.value = response.language;
      }

      return response;
    };
    const queryKey = [KEY_MY_CUSTOMER_INFO];
    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn,
      staleTime: 30 * 1000,
    });
  };

  const getMyCustomerState = (
    refetchInterval: Ref<number | false> = ref(false)
  ) => {
    const queryFn = async () => {
      return await apiClient.customers.retrieveState({
        customerId: '_me',
      });
    };
    const queryKey = [KEY_MY_CUSTOMER_STATE];
    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn,
      staleTime: 30 * 1000,
      refetchInterval,
    });
  };

  const invalidateCustomerState = (): Promise<void> => {
    return queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });
  };

  const registerCustomer = async (
    customerInfo: CustomerFormData
  ): Promise<Customer> => {
    const response = await apiClient.customers.register({
      customerId: '_me',
      data: customerInfo,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });
    return response;
  };

  const setBillingAddress = async (body: CustomerUpdate): Promise<Customer> => {
    const response = await apiClient.customers.update({
      customerId: '_me',
      data: body,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    return response;
  };

  const updateLanguageSelection = async (
    body: CustomerUpdate
  ): Promise<Customer> => {
    const response = await apiClient.customers.update({
      customerId: '_me',
      data: body,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_LANGUAGE] });
    return response;
  };

  const getCustomerZendeskToken = () => {
    const queryFn = async () => {
      const response = await apiClient.customers.retrieveZendeskToken({
        customerId: '_me',
      });
      return response;
    };
    const queryKey = [KEY_MY_ZENDESK_TOKEN];
    return useQuery({ queryKey, queryFn, enabled: isLoggedIn });
  };

  const enrollToLoyaltyProgram = async (): Promise<Customer> => {
    const response = await apiClient.customers.update({
      customerId: '_me',
      data: { inLoyaltyProgram: true },
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    return response;
  };

  const getMailingLists = () => {
    const queryFn = async () => {
      const response =
        await apiClient.customers.retrieveMailingListsForCustomer({
          customerId: '_me',
        });

      const mailingListsByName: { [key: string]: string[] } = {};

      response.mailingLists.forEach((list) => {
        const listName = list.split('_')[1];
        if (!mailingListsByName[listName]) {
          mailingListsByName[listName] = [];
        }
        mailingListsByName[listName].push(list);
      });

      return {
        mailingLists: mailingListsByName,
        subscribedLists: response.subscribedLists,
      };
    };

    const queryKey = [KEY_MY_MAILING_LISTS];
    return useQuery({ queryKey, queryFn, enabled: isLoggedIn });
  };

  const updateMailingLists = async (mailingLists: string[]): Promise<null> => {
    const response = await apiClient.customers.updateMailingListsForCustomer({
      customerId: '_me',
      data: mailingLists,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_MAILING_LISTS] });
    return response;
  };

  return {
    getMyCustomerInfo,
    getMyCustomerState,
    invalidateCustomerState,
    updateLanguageSelection,
    registerCustomer,
    setBillingAddress,
    getCustomerZendeskToken,
    enrollToLoyaltyProgram,
    getMailingLists,
    updateMailingLists,
  };
}
