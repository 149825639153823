import type { Language } from '@ruokaboksi/api-client';
import type { CookieRef } from '#app';

/**
 * Composable for fetching cookie about the current locale.
 * @returns The current locale.
 */
export default function useLocale() {
  const locale: CookieRef<Language> = useCookie('locale', {
    sameSite: 'strict',
    secure: true,
  });

  return {
    locale,
  };
}
