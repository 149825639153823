import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 35 35"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "currentColor" }, [
      _createElementVNode("path", { d: "m2.916 27.708 2.917 2.917h23.333l2.917-2.917zM4.374 26.25h24.748c.423-4.754-3.325-8.75-7.991-8.75-3.427 0-6.344 2.158-7.496 5.177-.598-.335-1.269-.554-1.969-.685v-8.867h2.552A3.286 3.286 0 0 0 17.5 9.844h13.125V7.656H17.5a3.286 3.286 0 0 0-3.28-3.281H4.373v2.187h1.459v1.094H4.374v2.188h1.459v1.093H4.374v2.188h1.459v10.777a6.636 6.636 0 0 0-1.459 2.348zm16.771-5.833c1.444 0 2.786.583 3.763 1.662.35.38.641.802.845 1.254h-9.216a5.133 5.133 0 0 1 4.608-2.916zM11.666 6.562h2.917v1.094h-2.917zm0 3.282h2.917v1.093h-2.917zM8.02 6.562H9.48v1.094H8.02zm0 3.282H9.48v1.093H8.02zm0 3.281H9.48v8.837c-.51.088-.992.248-1.459.438z" })
    ], -1)
  ])))
}
export default { render: render }