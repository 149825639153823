import type { PriceTier } from '@ruokaboksi/api-client';
import {
  Price,
  Week,
  ZERO_PRICE,
  resolveCampaignWeekItem,
} from '@ruokaboksi/utils';
import {
  type DeliveryWeekWithFormattedDays,
  addWeekProperties,
} from '../models';

/**
 * Composable for fetching information related to delivery weeks.
 */
export default function useDeliveryWeeks() {
  const { subscription } = useCustomerSubscriptions();
  const { getDeliveryCalendar, getDeliveryWeek } = useDeliveriesApi();
  const { getBoxTypeById } = useBoxTypeApi();
  const route = useRoute();
  const router = useRouter();

  const {
    data: initialWeeksResponse,
    isFetched: isFetchedDeliveryWeeks,
    isLoading: isLoadingDeliveryWeeks,
  } = getDeliveryCalendar();

  const initialWeeks = computed(() => {
    if (isFetchedDeliveryWeeks.value && initialWeeksResponse.value) {
      const items = initialWeeksResponse.value;
      return items;
    }
    return [];
  });
  /**
   * Array of available delivery weeks including the
   * past delivery week at index zero.
   */
  const deliveryWeeks = computed<DeliveryWeekWithFormattedDays[]>(() =>
    isFetchedDeliveryWeeks.value &&
    initialWeeks.value.length &&
    subscription.value
      ? addWeekProperties(initialWeeks.value, subscription.value)
      : []
  );

  /**
   * Potential delivery week as a string after validation.
   * @returns Empty string if no match is found.
   */
  const validatedQueryWeekString = computed<string>(() => {
    if (!route.query.selectedWeek || !deliveryWeeks.value.length) return '';

    const queryWeekString = route.query.selectedWeek?.toString() ?? '';
    const availableDeliveryWeeks = deliveryWeeks.value.slice(
      0,
      SELECTOR_WEEKS_AMOUNT
    );
    const firstAvailableWeek = availableDeliveryWeeks[0].weekString;

    // If query week is in the past and not available
    if (!queryWeekString || queryWeekString < firstAvailableWeek) {
      const initialWeek = availableDeliveryWeeks[2];

      router.replace({
        query: {
          ...route.query,
          selectedWeek: initialWeek.weekString,
        },
      });

      return initialWeek.weekString;
    }

    return queryWeekString;
  });

  /** Price tiers of the current selected box type. */
  const priceTiers = computed<PriceTier[]>(
    () => boxType.value?.priceTiers ?? []
  );

  /** Maximum amount of recipes in the current price tier. */
  const maxRecipes = computed<number>(() =>
    priceTiers.value?.length
      ? Math.max(...priceTiers.value.map((i) => i.recipeCount))
      : 0
  );

  /** Minimum amount of recipes in the current price tier. */
  const minRecipes = computed<number>(() =>
    priceTiers.value?.length
      ? Math.min(...priceTiers.value.map((i) => i.recipeCount))
      : 0
  );

  /** Delivery week based on the current route query string. */
  const selectedDeliveryWeek = computed<
    DeliveryWeekWithFormattedDays | undefined
  >(() =>
    deliveryWeeks.value?.find(
      (w) => w.weekString === validatedQueryWeekString.value
    )
  );

  const paymentDate = computed(() => selectedDeliveryWeek.value?.paymentDate);

  const { data: deliveryWeek } = getDeliveryWeek(paymentDate);

  /**
   * Index number of the selected delivery week in
   * the array that holds all delivery weeks.
   */
  const selectedDeliveryWeekIndex = computed<number | undefined>(() =>
    deliveryWeeks.value?.findIndex(
      (w) => w.weekString === validatedQueryWeekString.value
    )
  );

  /**
   * The selected delivery week number as a string.
   * @example '42'
   */
  const selectedDeliveryWeekNumberString = computed<string>(() => {
    return selectedDeliveryWeek?.value?.weekNumberString || '';
  });

  /** The box type ID of selected delivery week. */
  const boxTypeId = computed<string>(
    () => selectedDeliveryWeek.value?.boxTypeId || ''
  );

  /** The box type of selected delivery week. */
  const { data: boxType } = getBoxTypeById(boxTypeId);

  /** Delivery date of the selected delivery week. */
  const deliveryDate = computed<Date | string>(
    () => selectedDeliveryWeek?.value?.deliveryDate ?? ''
  );

  /** @returns `true` if selected delivery week is paused. */
  const isSelectedDeliveryWeekPaused = computed<boolean>(
    () => !!selectedDeliveryWeek.value?.paused
  );

  /** @returns `true` if selected delivery week can be edited. */
  const isSelectedDeliveryWeekEditable = computed<boolean>(
    () => !!selectedDeliveryWeek.value?.editable
  );

  /** @returns `true` if the deliveryWeek can be paused or unpaused. */
  const isSelectedDeliveryWeekPauseEditable = computed<boolean>(
    () => !selectedDeliveryWeek.value?.locked
  );

  const selectedDeliveryWeekDiscountType = computed<string>(
    () => deliveryWeek.value?.activeCampaign?.campaign.discountType || ''
  );

  const selectedDeliveryWeekDiscountResolvedValue = computed<number>(() => {
    if (
      !deliveryWeek.value ||
      !subscription.value ||
      !deliveryWeek.value?.activeCampaign?.campaign
    ) {
      return 0;
    }

    if (!('discounts' in deliveryWeek.value.activeCampaign.campaign)) {
      return 0;
    }

    const resolvedCampaignWeekItem = resolveCampaignWeekItem(
      deliveryWeek.value?.activeCampaign,
      Week.fromPaymentDate(
        new Date(deliveryWeek.value?.paymentDate),
        subscription.value?.defaultDeliverySlot
      ),
      deliveryWeek.value.activeCampaign.campaign.discounts
    );

    return resolvedCampaignWeekItem ?? 0;
  });

  /** Discount for selected delivery week. */
  const selectedDeliveryWeekDiscount = computed<number>(() =>
    Math.max(deliveryWeek.value?.totalPrice?.discount || 0, 0)
  );

  /** Available credit for selected delivery week. */
  const selectedDeliveryWeekCredit = computed<number>(() =>
    Math.max(deliveryWeek.value?.totalPrice?.credit || 0, 0)
  );

  /** Recipe count for selected delivery week. */
  const selectedDeliveryWeekRecipeCount = computed<number>(
    () => deliveryWeek.value?.recipes?.length || 0
  );

  const priceTier = computed<PriceTier | undefined>(() =>
    boxType.value?.priceTiers?.find(
      (tier) => deliveryWeek.value?.recipes?.length === tier.recipeCount
    )
  );

  const selectedDeliveryWeekPrice = computed<Price>(() => {
    if (!deliveryWeek.value) {
      return ZERO_PRICE;
    }

    return new Price(deliveryWeek.value?.totalPrice);
  });

  /** @returns `true` if discounted total differs from total price. */
  const hasDiscount = computed<boolean>(() =>
    selectedDeliveryWeekPrice.value.hasPriceModifier()
  );

  return {
    boxType,
    boxTypeId,
    deliveryDate,
    deliveryWeeks,
    hasDiscount,
    isFetchedDeliveryWeeks,
    isLoadingDeliveryWeeks,
    isSelectedDeliveryWeekEditable,
    isSelectedDeliveryWeekPauseEditable,
    isSelectedDeliveryWeekPaused,
    maxRecipes,
    minRecipes,
    priceTier,
    priceTiers,
    selectedDeliveryWeek,
    selectedDeliveryWeekDiscount,
    selectedDeliveryWeekCredit,
    selectedDeliveryWeekIndex,
    selectedDeliveryWeekNumberString,
    selectedDeliveryWeekPrice,
    selectedDeliveryWeekRecipeCount,
    validatedQueryWeekString,
    selectedFullDeliveryWeek: deliveryWeek,
    selectedDeliveryWeekDiscountType,
    selectedDeliveryWeekDiscountResolvedValue,
  };
}
